


<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import store from "@/state/store";
var state = store._modules.root._children.auth.state;
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      notfication_settings: [],
      orders: [],
      transactions: [],
      user_details: [],
      categories: [],
      rate: [],
      coupons: [],
      temp_driver_offers: [],
      page: 1,
      id: 0,
      limit: 20,
      tot_pages: 0,
      state: state,
    };
  },
  methods: {
    get(page) {
      console.log(page);
      let id = this.$route.params.id;
      console.log(id);
      this.http.get("users/delivery/details/" + id).then((res) => {
        this.tot_pages = Math.ceil(res.tot / this.limit);
        this.user_details = res.data.user_details;
        this.orders = res.data.orders;
        this.categories = res.data.cateogries;
        this.coupons = res.data.coupons;
        this.rate = res.data.rate;
        this.temp_driver_offers = res.data.temp_driver_offers;
        this.user_details = res.data.user_details;
        this.transactions = res.data.transactions.map((item) => {
          return item.user_wallet_statements;
        });
      });
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.get(1);
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.drivers.text')"
    />
    <div class="row card">
      <b-tabs
        content-class="p-4"
        class="pt-2"
        nav-wrapper-class="nav-item"
        nav-class="justify-content-start nav-tabs-custom"
      >
        <!-- marketing channels -->

        <b-tab :title="$t('menu.menuitems.user_details.text')" active>
          <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table mt-1">
            <thead>
              <tr
                class="text-light text-center"
                style="background-color: #2a3042 !important"
              >
                <th scope="col">{{ $t("users_dateils.id") }}</th>
                <th scope="col">{{ $t("users_dateils.image") }}</th>
                <th scope="col">{{ $t("users_dateils.registry_id") }}</th>
                <th scope="col">{{ $t("users_dateils.official_name") }}</th>
                <th scope="col">{{ $t("users_dateils.official_tel") }}</th>
                <th scope="col">{{ $t("users_dateils.official_email") }}</th>
                <th scope="col">{{ $t("users_dateils.created") }}</th>
                <th scope="col">{{ $t("users_dateils.updated") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(app, index) in user_details"
                :key="app"
                class="text-center"
              >
                <td>{{ index + 1 }}</td>
                <td>
                  <img
                    :src="
                      $store.state?.auth?.mediaUrl +
                      app?.image?.split('public')[1]
                    "
                    style="width: 64px; height: 64px"
                    alt=""
                  />
                </td>
                <td>{{ app?.user?.name }}</td>
                <td>{{ app?.name }}</td>
                <td>{{ app?.user?.tel }}</td>
                <td>{{ app?.user?.email }}</td>
                <td>{{ app.created.split("T")[0] }}</td>
                <td>{{ app.updated.split("T")[0] }}</td>
              </tr>
            </tbody>
          </table>
        </b-tab>

        <b-tab :title="$t('menu.menuitems.prev_offers.text')">
          <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table mt-1">
            <thead>
              <tr
                class="text-light text-center"
                style="background-color: #2a3042 !important"
              >
                <th scope="col">{{ $t("offers.id") }}</th>
                <th scope="col">{{ $t("offers.customer") }}</th>
                <th scope="col">{{ $t("offers.date") }}</th>
                <th scope="col">{{ $t("offers.status") }}</th>
                <th scope="col">{{ $t("offers.location") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(app, i) in offers" :key="app" class="text-center">
                <td>{{ i + 1 }}</td>
                <td>{{ app.order_id }}</td>
                <td>{{ app.offer }}</td>
                <td>
                  <img
                    :src="
                      $store.state?.auth?.mediaUrl +
                      app.image.split('public')[1]
                    "
                    style="width: 64px; height: 64px"
                    alt=""
                  />
                </td>
                <td>{{ app.created.split("T")[0] }}</td>
                <td>{{ app.updated.split("T")[0] }}</td>
              </tr>
            </tbody>
          </table>
        </b-tab>

        <!-- marketing channels -->
        <b-tab :title="$t('menu.menuitems.orders.text')">
          <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table mt-1">
            <thead>
              <tr
                class="text-light text-center"
                style="background-color: #2a3042 !important"
              >
                <th scope="col">{{ $t("orders.id") }}</th>
                <th scope="col">{{ $t("orders.custmor_id") }}</th>
                <th scope="col">{{ $t("orders.location") }}</th>
                <th scope="col">{{ $t("orders.total") }}</th>
                <th scope="col">{{ $t("orders.status_id") }}</th>
                <th scope="col">{{ $t("orders.created") }}</th>
                <th scope="col">{{ $t("orders.updated") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="app in orders" :key="app" class="text-center">
                <td>
                  <router-link :to="'/order-deatiles/' + app?.id">
                    # {{ app.id }}</router-link
                  >
                </td>
                <td>
                  <router-link :to="'/order-deatiles/' + app?.id">
                    {{ app?.user?.name }}
                  </router-link>
                </td>
                <td>
                  <router-link :to="'/order-deatiles/' + app?.id">
                    {{ app?.user_address?.address }}
                  </router-link>
                </td>
                <td>
                  <router-link :to="'/order-deatiles/' + app?.id">
                    {{
                      parseInt(app?.order_price) -
                      parseInt(app?.discount_price) +
                      parseInt(app?.tip)
                    }}
                  </router-link>
                </td>
                <td>
                  <router-link :to="'/order-deatiles/' + app?.id">
                    {{ app?.status?.status_ar }}
                  </router-link>
                </td>
                <td>{{ app?.created.split("T")[0] }}</td>
                <td>{{ app?.updated.split("T")[0] }}</td>
              </tr>
            </tbody>
          </table>
        </b-tab>

        <!-- marketing channels -->
        <b-tab :title="$t('menu.menuitems.rate.text')">
          <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table mt-1">
            <thead>
              <tr
                class="text-light text-center"
                style="background-color: #2a3042 !important"
              >
                <th scope="col">{{ $t("orders.id") }}</th>
                <th scope="col">{{ $t("popups.comment") }}</th>
                <th scope="col">{{ $t("popups.points") }}</th>
                <th scope="col">{{ $t("orders.user") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(app, i) in rate" :key="app" class="text-center">
                <td>{{ i + 1 }}</td>
                <td>{{ app?.comment }}</td>
                <td>{{ app?.points }}</td>
                <td>{{ app?.user?.name }}</td>
              </tr>
            </tbody>
          </table>
        </b-tab>

        <!-- marketing channels -->
        <b-tab :title="$t('menu.menuitems.transactions.text')">
          <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table mt-1">
            <thead>
              <tr
                class="text-light text-center"
                style="background-color: #2a3042 !important"
              >
                <th scope="col">{{ $t("transactions.id") }}</th>
                <th scope="col">{{ $t("transactions.amount") }}</th>
                <th scope="col">{{ $t("transactions.note") }}</th>
                <th scope="col">{{ $t("transactions.status") }}</th>
                <th scope="col">{{ $t("transactions.date") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(transaction, i) in transactions"
                :key="transaction"
                class="text-center"
              >
                <template v-for="data in transaction" :key="data">
                  <td>{{ i + 1 }}</td>
                  <td>{{ data?.amount }}</td>
                  <td>{{ data?.statement_note }}</td>
                  <td>
                    {{
                      $i18n.locale == "ar"
                        ? data?.status_wallet_statement_status?.name_ar
                        : data?.status_wallet_statement_status?.name
                    }}
                  </td>
                  <td>{{ data?.created }}</td>

                </template>
              </tr>
            </tbody>
          </table>
        </b-tab>
      </b-tabs>
    </div>
    <!--end-->
  </Layout>
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>




